<template>
    <div class="system-module">
        <h3 class="block-title system-module__title">Модули</h3>
        <ul class="system-module__list">
            <item v-for="item in modules" :module="item" :key="item.id"></item>
        </ul>
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "List",
        components: {
            Item : () => import('./Item')
        },
        data() {
            return {
                modules: []
            }
        },
        created() {
            session
                .get('/api/v1/settings/modules/')
                .then(response => {
                    this.modules = response.data;
                })
                .catch(() => {
                });
        },
    }
</script>

<style scoped>

</style>
